// src/pages/ecommerce.js
import React from "react"
import ECOMA_IMG_1 from "../../asset/img/ecommerce/hotlobster.png"
import ECOMA_IMG_2 from "../../asset/img/ecommerce/garage1st.png"
import ECOMA_IMG_3 from "../../asset/img/ecommerce/biogenics.png"
import "../styles/ecommerce.css"
import Layout from "../components/layout"
import Seo from "../components/seo"

export default function Ecommerce() {

  return (
    <Layout>
        <div class="main-ec">
          <Seo title="E-commerce" description="eコマース" noindex="noindex" />
          <div class="heading-ec">
            <h1>E-COMMERCE</h1>
            <p>Eコマース</p>
          </div>
          <ul class="sample-image">
            <li><img src={ECOMA_IMG_1} alt="ホットロブスター"></img></li>
            <li><img src={ECOMA_IMG_2} alt="ガレージファースト"></img></li>
            <li><img src={ECOMA_IMG_3} alt="バイオジェニックス"></img></li>
          </ul>

          <div class="body-ec">
            <p>自社ネットショップの運用のほか、ネットショップの制作、ネットショップの運用代行、コンサルティングを行っています。ネットショップ業務の周辺領域である商品撮影サービス、パッケージデザイン、ショップカードやチラシの制作なども承ります。</p>
          </div>
          <div class="backtop">
            <a href="https://jpholic.jp/#ビジネス"><span>back to top</span></a>
          </div>
        </div>
    </Layout>

  )
}
